import "slick-carousel/slick/slick.css"
import "./slickstyles.css"
import "slick-carousel/slick/slick-theme.css"

import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import styled from "styled-components"

import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import ButtonLink from "../../components/ButtonLink"

const SliderWrapper = styled.div`
  margin-top: 30px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 54px;
    margin-bottom: 64px;
  }
`

const Wrapper = styled.div`
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 0;
    padding-bottom: 50px;
  }
`
const Slide = styled.div`
  display: unset !important;

  &:hover {
    cursor: pointer;
  }
`

const PortfolioItemTitle = styled.div`
  color: #003d6a;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 10px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 0px;
    font-size: 24px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 15px;
  }
`

const PortfolioItemSubtitle = styled.div`
  color: #003d6a;
  margin-top: 16px;
  margin-bottom: 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
`

const PortfolioSubtitle = styled.div`
  color: #003d6a;
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    margin-top: 23px;
    margin-bottom: 27px;
  }
`

const PortfolioContentTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #15316c;
  min-width: 92px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    line-height: 20px;
  }
`
const PortfolioContent = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #15316c;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    line-height: 20px;
  }
`
const PortfolioContentText = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #15316c;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    line-height: 20px;
  }
`

const FeaturedTitleActive = styled.div`
  margin-top: 5px;
  color: #003d6a;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`

const FeaturedTitleInactive = styled.div`
  margin-top: 5px;
  color: #003d6a;
  opacity: 27%;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`

const SliderImg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  height: 192px;
  /* width: 290px; */
  object-fit: cover;

  @media ${({ theme }) => theme.breakpoint.md} {
    height: 322px;
    width: 534px;
    object-fit: cover;
  }
`

const FeaturedImg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  border-radius: 0 25px 0 25px;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.27);
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 266px !important;
  }
`

const HighlightLine = styled.div`
  height: 2px;
  width: 186px;
  background-color: #c0d104;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
`
const Title = styled.h2`
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    /* margin-bottom: 8px; */
  }
`

const StyledCol = styled(Col)`
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 10px;
  }
`

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`

const SectionWrapper = styled.section`
  background: #fafafa;
  @media ${({ theme }) => theme.breakpoint.md} {
    background: initial;
  }
`

const TitleSecondSection = styled.h2`
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
  }
  @media ${({ theme }) => theme.breakpoint.xl} {
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
  }
`
const SecondSectionWrapper = styled.section`
  background: white;
  padding-top: 35px;
  margin-bottom: 50px;
  @media ${({ theme }) => theme.breakpoint.md} {
  }
`

const ImageCol = styled(Col)`
  margin-top: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 0;
  }
  img {
    height: 100%;
    object-fit: cover;
    margin-bottom: 0;
  }
`

const VerticalSlide = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`
const HorizontalSlide = styled.div`
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 50px;
    height: 2px;
    background: #c0d104;
  }
  position: relative;
  margin-bottom: 22px;
  margin-top: 17px;
  padding-top: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #003d6a;
  padding-right: 15px;
`

const HorizontalSlider = styled(Slider)`
  .slick-slide {
    opacity: 0.27;
  }
  .slick-current {
    opacity: 1;
  }
`

const settingsImage = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 10000,
  draggable: false,
  accessibility: false,
  swipe: false,
  vertical: true,
  verticalSwiping: false,
  pauseOnHover: false,
}
const settingsContent = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  // autoplay: true,
  // autoplaySpeed: 5000,
  draggable: false,
  accessibility: false,
  swipe: false,
  pauseOnHover: false,
  fade: true,
}

const settingsVertical = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 2000,
  // autoplay: true,
  // autoplaySpeed: 5000,
  draggable: false,
  accessibility: false,
  swipe: false,
  vertical: true,
  verticalSwiping: true,
  infinite: true,
  pauseOnHover: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
}

const settingsHorizontal = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  speed: 2000,
  // autoplay: true,
  // autoplaySpeed: 5000,
  draggable: false,
  accessibility: false,
  swipe: false,
  vertical: false,
  infinite: true,
  pauseOnHover: true,
  focusOnSelect: true,
  // centerMode: true,
  variableHeight: true,
}

const HomepagePortfolio = ({
  featuredportfolios,
  titles,
  subtitle,
  featuredimages,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [nav3, setNav3] = useState()

  return (
    <div>
      <SectionWrapper>
        <Container overflowDisabled>
          <Wrapper>
            <Title>Featured Properties</Title>
            <SliderWrapper>
              <Row>
                <Col xs={12} md={12} lg={2} xl={2}>
                  <Hidden mdDown>
                    <Slider
                      {...settingsVertical}
                      className="vertical-titles"
                      asNavFor={nav1}
                      ref={slider2 => setNav2(slider2)}
                    >
                      {featuredportfolios.map(item => (
                        <VerticalSlide key={item.title}>
                          {item.title}
                        </VerticalSlide>
                      ))}
                    </Slider>
                  </Hidden>
                  <Hidden lgUp>
                    <HorizontalSlider
                      {...settingsHorizontal}
                      asNavFor={nav1}
                      ref={slider2 => setNav2(slider2)}
                    >
                      {featuredportfolios.map(item => (
                        <HorizontalSlide key={item.title}>
                          {item.title}
                        </HorizontalSlide>
                      ))}
                    </HorizontalSlider>
                  </Hidden>
                </Col>
                <Col sm={6} md={6} lg={5} xl={5} className="portfolioSlider">
                  <Slider
                    {...settingsImage}
                    asNavFor={nav3}
                    ref={slider1 => setNav1(slider1)}
                  >
                    {featuredportfolios.map(item => (
                      <Slide key={item.title}>
                        <SliderImg
                          src={item.image.sourceUrl}
                          alt={item.image.alt}
                        />
                      </Slide>
                    ))}
                  </Slider>
                </Col>
                <Col xs={12} sm={6} md={5} xl={5}>
                  <Slider
                    {...settingsContent}
                    asNavFor={nav2}
                    ref={slider3 => setNav3(slider3)}
                  >
                    {featuredportfolios.map(item => (
                      <Slide key={item.title}>
                        <PortfolioItemTitle>{item?.title}</PortfolioItemTitle>
                        <Row>
                          <StyledCol xs={3} md={3} xl={3}>
                            <PortfolioContentTitle>
                              Location:
                            </PortfolioContentTitle>
                          </StyledCol>
                          <StyledCol xs={9} md={9} xl={9}>
                            <PortfolioContent>
                              {item?.location}
                            </PortfolioContent>
                          </StyledCol>
                          <StyledCol xs={3} md={3} xl={3}>
                            <PortfolioContentTitle>Date:</PortfolioContentTitle>
                          </StyledCol>
                          <StyledCol xs={9} md={9} xl={9}>
                            <PortfolioContent>{item?.date}</PortfolioContent>
                          </StyledCol>
                        </Row>
                        <PortfolioItemSubtitle>
                          {item?.subtitle}
                        </PortfolioItemSubtitle>
                        <PortfolioContentText>
                          {item?.content}
                        </PortfolioContentText>
                      </Slide>
                    ))}
                  </Slider>
                </Col>
              </Row>
            </SliderWrapper>
          </Wrapper>
        </Container>
      </SectionWrapper>
      <Container overflowDisabled>
        <SecondSectionWrapper>
          <Row>
            <Col xs={12} sm={12} md={4} xl={4}>
              {titles &&
                titles.map(item => (
                  <TitleSecondSection key={item.title}>
                    {item.title}
                  </TitleSecondSection>
                ))}
              <PortfolioSubtitle>{subtitle}</PortfolioSubtitle>
              <ButtonLink
                to="/portfolio"
                title="Portfolio"
                color="#eb7c00"
                borderColor="#eb7c00"
                initialText="View Portfolio"
                solid
                changedText="Click Here"
              />
            </Col>
            <ImageCol sm={12} md={8} xl={4}>
              <FeaturedImg
                src={featuredimages[0].image.sourceUrl}
                alt={featuredimages[0].image.alt}
              />
            </ImageCol>
            <Hidden lgDown>
              <ImageCol xs={12} sm={6} md={4} xl={4}>
                <FeaturedImg
                  src={featuredimages[1].image.sourceUrl}
                  alt={featuredimages[1].image.alt}
                />
              </ImageCol>
            </Hidden>
          </Row>
        </SecondSectionWrapper>
      </Container>
    </div>
  )
}

export default HomepagePortfolio
