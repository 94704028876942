import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import Slider from "react-slick"
import styled from "styled-components"

import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import ButtonLink from "../../components/ButtonLink"

const Wrapper = styled.div`
  padding-bottom: 50px;
  width: 100%;
`
const settings = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 10000,
  draggable: true,
  accessibility: false,
  swipe: true,
}
const Slide = styled.div``
const SliderImg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  /* border: 2px solid white; */
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  z-index: -1;
  height: 350px;
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 465px;
  }
`
const SliderWrapper = styled.div`
  /* right: -2px; */
  position: relative;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.11);
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;

  margin-left: calc((100vw - 1344px) / 2 - 104px);
  margin-right: calc((100vw - 1344px) / 2);

  @media (max-width: 1610px) {
    margin-left: 0px;
    margin-right: calc((100vw - 1344px) / 2);
  }

  @media (max-width: 1280px) {
    margin-left: 0px;
    margin-right: 16px;
  }
`
const SlideWrapper = styled.div`
  position: relative;
  max-width: 100%;
  color: white;
  display: flex;
  align-items: center;
`

const LinearGradient = styled.div`
  width: 100%;
  /* border: 2px solid white; */
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  background-image: linear-gradient(
    to left,
    rgb(0, 0, 0, 0),
    rgb(0, 0, 0, 0.87)
  );
  height: 350px;
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 465px;
  }
`
const SliderText = styled.div`
  /* position: absolute; */
  max-width: 486px;
  /* top: 140px;
  left: 140px;
  @media (max-width: 768px) {
    left: 15px;
    top: 100px;
  } */
`
const SliderTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 3px solid #ee751a;
  padding-bottom: 13px;
  @media ${({ theme }) => theme.breakpoint.md} {
    border-bottom: 0px;
    padding-bottom: 0px;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
  }
`
const SliderContent = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  p {
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    margin-top: 16px;
    margin-bottom: 27px;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
`

const LatestNewsWrapper = styled.div`
  margin-top: 24px;
`
const CurrentSlide = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  letter-spacing: 1.9px;
  color: #ee751a;
`
const TotalSlides = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 1.9px;
  color: #ee751a;
`
const SlideBottomTitleActive = styled.span`
  color: #003d6a;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }
`

const SlideBottomTitleInactive = styled.span`
  &:hover {
    cursor: pointer;
  }
  color: #003d6a;
  opacity: 27%;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }
`
const HighlightLine = styled.div`
  height: 2px;
  width: 38px;
  background-color: #eb7c00;
  margin-bottom: 8px;
`
const StyledContainer = styled(Container)`
  position: absolute;
  left: 0;
  right: 0;
  margin-right: 0;
  padding-top: 0;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 25px;
  }
  @media ${({ theme }) => theme.breakpointDown.xl} {
    /* @media (max-width: 1280px) { */
    margin: 0 auto;
  }
`

const StyledLatestNewsWrapper = styled(Container)`
  position: relative;
  z-index: 2;
  margin-right: 0px;
  padding-bottom: 30px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-bottom: 25px;
  }
  /* &:before {
    content: "";
    height: 620px;
    width: calc(100% + 98px);
    left: -101px;
    top: -502px;
    position: absolute;
    box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.11);
    background: white;
    z-index: -2;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 22px;
  } */
  @media ${({ theme }) => theme.breakpointDown.xl} {
    margin: 0 auto;
  }
`

const TitleWrapperLatest = styled.div`
  position: relative;
  top: -10px;
  h2 {
    color: #003d6a;
    font-size: 25px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 9px;
    @media ${({ theme }) => theme.breakpoint.md} {
      margin-bottom: 0px;
      font-size: 40px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }
`

const SliderCounterDiv = styled.div`
  display: none;
  @media ${({ theme }) => theme.breakpoint.md} {
    display: block;
  }
`

const SlideCounter = ({ current, total }) => {
  return (
    <SliderCounterDiv>
      <CurrentSlide>{current}</CurrentSlide>
      <TotalSlides>/{total}</TotalSlides>
    </SliderCounterDiv>
  )
}

const HomepageHero = () => {
  const [slider1, setRefSlider1] = useState()
  const [currentIndex, setCurrentIndex] = useState(0)

  const HeadingQuerry = useStaticQuery(graphql`
    query HeadingQuerry {
      wpgraphql {
        newsItem(first: 6) {
          nodes {
            title
            newsItem {
              excerpt
              country
              city
            }
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  `)
  let nodes = HeadingQuerry?.wpgraphql?.newsItem?.nodes
  return (
    <Container maxWidth={false} disableGutters={true} overflowDisabled>
      <Wrapper>
        <SliderWrapper>
          <Slider
            {...settings}
            afterChange={index => {
              setCurrentIndex(index)
            }}
            ref={slider1 => setRefSlider1(slider1)}
          >
            {nodes &&
              nodes.map(item => (
                <Slide key={item?.title}>
                  <SlideWrapper>
                    <StyledContainer>
                      <SliderText>
                        <SlideCounter current={currentIndex + 1} total={6} />
                        <SliderTitle>{item?.title}</SliderTitle>
                        <SliderContent
                          dangerouslySetInnerHTML={{
                            __html: item?.newsItem?.excerpt,
                          }}
                        />
                        <ButtonLink
                          to="/news"
                          title="News"
                          color="white"
                          borderColor="#EE751A"
                          initialText="Pradera News"
                          changedText="Click Here"
                          solid
                        />
                      </SliderText>
                    </StyledContainer>

                    <LinearGradient>
                      <SliderImg
                        src={item?.featuredImage?.node?.sourceUrl}
                        alt={item?.featuredImage?.node?.alt}
                      />
                    </LinearGradient>
                  </SlideWrapper>
                </Slide>
              ))}
          </Slider>
          <StyledLatestNewsWrapper overflowDisabled>
            <LatestNewsWrapper>
              <Row>
                <Col xs={12} md={12} lg={2} xl={2}>
                  <TitleWrapperLatest>
                    <h2>
                      Latest{" "}
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      News
                    </h2>
                    <Hidden mdDown>
                      <SlideCounter
                        current={currentIndex + 1}
                        total={nodes?.length}
                      />
                    </Hidden>
                  </TitleWrapperLatest>
                </Col>
                <Col xs={6} md={6} lg={3} xl={3}>
                  <HighlightLine />
                  <SlideBottomTitleActive
                    onClick={() => {
                      slider1.slickGoTo(currentIndex)
                    }}
                  >
                    {nodes[currentIndex]?.title}
                  </SlideBottomTitleActive>
                </Col>
                <Col xs={6} md={6} lg={3} xl={3}>
                  <HighlightLine />
                  <SlideBottomTitleInactive
                    onClick={() => {
                      slider1.slickGoTo(currentIndex + 1)
                    }}
                  >
                    {nodes[(currentIndex + 1) % nodes?.length]?.title}
                  </SlideBottomTitleInactive>
                </Col>
                <Hidden mdDown>
                  <Col xs={3} md={6} lg={3} xl={3}>
                    <HighlightLine />
                    <SlideBottomTitleInactive
                      onClick={() => {
                        slider1.slickGoTo(currentIndex + 2)
                      }}
                    >
                      {nodes[(currentIndex + 2) % nodes?.length]?.title}
                    </SlideBottomTitleInactive>
                  </Col>
                </Hidden>
              </Row>
            </LatestNewsWrapper>
          </StyledLatestNewsWrapper>
        </SliderWrapper>
      </Wrapper>
    </Container>
  )
}

export default HomepageHero
