
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"

import Layout from "../src/components/Layout"
import Seo from "../src/components/Seo"
import Helmet from "react-helmet"


// Sections
import HomepageHero from '../src/layouts/HomepageHero';
import HomepageAbout from '../src/layouts/HomepageAbout';
import HomepagePortfolio from '../src/layouts/HomepagePortfolio';

const Page = ({ pageContext, location }) => {
  const {
    page: { title, pageBuilder, isFrontPage },
  } = pageContext

  const layouts = pageBuilder.layouts || []

  return (
    <Layout locationSearch={location.search}>
    {isFrontPage ? <Helmet bodyAttributes={{ class: "homepage" }} /> : null}
      <Seo title={title}/>

      {
        layouts.map((layout, index) => {
          
              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_HomepageHero') {
                  return <HomepageHero {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_HomepageAbout') {
                  return <HomepageAbout {...layout} key={index} />;
              }
            

              if (layout.fieldGroupName === 'page_Pagebuilder_Layouts_HomepagePortfolio') {
                  return <HomepagePortfolio {...layout} key={index} />;
              }
            
        })
      }

    </Layout>
  )
}

export default Page
  