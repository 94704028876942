import React from "react"
import styled from "styled-components"
import ButtonLink from "../../components/ButtonLink"

import Container from "~layout/Container"
import Row from "~layout/Row"
import Col from "~layout/Col"

const Wrapper = styled.div`
  width: 100%;
  /* padding-top: 35px; */
  padding-bottom: 35px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const DetailTitle = styled.h3`
  color: #6fc2b4;
  margin-bottom: 10px;

  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  @media ${({ theme }) => theme.breakpoint.md} {
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
  }
`

const DetailContent = styled.div`
  color: #003d6a;
  margin-bottom: 20px;
  text-align: center;
  font-size: 13px;
  font-weight: 250;
  line-height: 18px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 36px;

    text-align: left;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
`

const Title = styled.h2`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 23px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 0;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    font-size: 40px;
    line-height: 45px;
  }
`

const HomepageAbout = ({ title, details }) => {
  return (
    <Container>
      <Wrapper>
        <Row>
          <Col xs={12} md={6} md={6} xl={6}>
            <Title>{title}</Title>
          </Col>
          <Col item xs={12} md={6} xl={6}>
            <Row>
              {details &&
                details.map(detail => (
                  <Col key={detail.title} xs={6} md={6} xl={6}>
                    <DetailTitle>{detail.title}</DetailTitle>
                    <DetailContent>{detail.content}</DetailContent>
                  </Col>
                ))}
              <Col xs={12} md={6} md={6} xl={6}>
                <ButtonLink
                  to="/about"
                  title="About"
                  color="#76C5B7"
                  borderColor="#76C5B7"
                  solid
                  initialText="Learn More"
                  changedText="Click Here"
                  solid
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Wrapper>
    </Container>
  )
}

export default HomepageAbout
